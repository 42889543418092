import { createUserManager } from 'redux-oidc'

const authConfig = {
  authority: process.env.REACT_APP_OIDC_BASE_URI,
  client_id: process.env.REACT_APP_OIDC_CLIENT_IMTAPP_ID,
  client_secret: process.env.REACT_APP_OIDC_CLIENT_IMTAPP_SECRET,
  redirect_uri: `${window.location.origin}/oauth/callback`,
  response_type: 'id_token token',
  scope: 'openid profile groups params',
  filterProtocolClaims: true,
  post_logout_redirect_uri: window.location.origin,
  signoutRedirect: '/',
  end_session_endpoint: '/',
  silent_redirect_uri: `${window.location.origin}/oauth/renew`,
  includeIdTokenInSilentRenew: false,
  automaticSilentRenew: true,
}
const userManager = createUserManager(authConfig)

export default userManager
