import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import createOidcMiddleware, { loadUser }  from 'redux-oidc';

import userManager from '../utils/userManager';
import rootReducer from './reducers/rootReducer';

export const configureStore = preloadedState => {
  const oidcMiddleware = createOidcMiddleware(userManager);
  const middlewares = [thunk, oidcMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const storeEnhancers = [middlewareEnhancer];

  const composedEnhancer = composeWithDevTools(
    ...storeEnhancers
  );

  const store = createStore(rootReducer, preloadedState, composedEnhancer);
  loadUser(store, userManager)

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./reducers/rootReducer', () => {
        const newRootReducer = require('./reducers/rootReducer').default;
        store.replaceReducer(newRootReducer);
      });
    }
  }

  return store;
};
