import React, { useEffect } from 'react'
import userManager from '../../../utils/userManager'

/**
 * Called when the session is about to expire
 */
const RenewPage = () => {
  useEffect(() => {
    async function initLoad() {
      try {
        await userManager.signinSilentCallback();
      } catch (error) {
        console.log("There was an error on silient renew", error)
      }
    
    }

    initLoad()
  }, [])

  return <div />
}


export default RenewPage
