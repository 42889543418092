import { combineReducers } from 'redux'
import { reducer as FormReducer } from 'redux-form'
import { reducer as toastrReducer } from 'react-redux-toastr'
import { reducer as oidcReducer } from 'redux-oidc'
import asyncReducer from '../features/async/asyncReducer'
import modalReducer from '../features/modals/modalReducer'
import fundingNeedReducers from '../fundingNeeds/redux/reducers'
import userReducers from '../features/user/redux/reducer'


const rootReducer = combineReducers({
  form: FormReducer,
  async: asyncReducer,
  modals: modalReducer,
  toastr: toastrReducer,
  oidc: oidcReducer,
  fundingNeed: fundingNeedReducers,
  user: userReducers,
})

export default rootReducer
