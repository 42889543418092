
import { createReducer } from '../../common/utils/reducerUtil'
import {
  FETCH_FUNDINGNEED_BY_ID,
  SET_ACTIVE_MENU,
  FETCH_PICKLISTS,
} from './constants'

const initialState = {

}

export const fetchFundingNeedById = (state, payload) => {
  if (payload.fundingNeed) {
    return {
      ...state,
      fundingNeed: payload.fundingNeed,
    }
  }
  return state
}

export const fetchPicklists = (state, payload) => {
  if (payload.picklists) {
    //return payload.picklists
    return {
      ...state,
      picklists: payload.picklists,
    }
  }
  return state
}

export const setActiveMenu = (state, payload) => {
  return {
    ...state,
    activeMenu: payload.activeMenu,
  }
}

export default createReducer(initialState, {
  [FETCH_FUNDINGNEED_BY_ID]: fetchFundingNeedById,
  [FETCH_PICKLISTS]: fetchPicklists,
  [SET_ACTIVE_MENU]: setActiveMenu,
})
