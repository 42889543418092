import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import Loadable from 'react-loadable'
import LoadingComponent from './common/ui/LoadingComponent'
import CallbackPage from './features/auth/CallbackPage'
import RenewPage from './features/auth/RenewPage'
import CheckAuth from './features/auth/CheckAuth'
import { FundingNeedProvider } from './fundingNeeds/components/FundingNeedContext'
import { UserProvider } from './features/user/UserContext'

const AsyncHomePage = Loadable({
  loader: () => import('./pages/Homepage'),
  loading: LoadingComponent,
})

const AsyncAboutPage = Loadable({
  loader: () => import('./pages/About'),
  loading: LoadingComponent,
})

const AsyncNavBar = Loadable({
  loader: () => import('./features/nav/PrimaryNav'),
  loading: LoadingComponent,
})

// Dashboard Page
const AsyncDashboard = Loadable({
  loader: () => import('./fundingNeeds/Dashboard'),
  loading: LoadingComponent,
})

// Archive Dashboard Page
const AsyncArchiveDashboard = Loadable({
  loader: () => import('./fundingNeeds/ArchiveDashboard'),
  loading: LoadingComponent,
})

const AsyncMyNeedsDashboard = Loadable({
  loader: () => import('./fundingNeeds/MyFundingNeedsDashboard'),
  loading: LoadingComponent,
})

// Create Page
const AsyncFundingNeedCreateEditPage = Loadable({
  loader: () => import('./fundingNeeds/CreateEdit'),
  loading: LoadingComponent,
})

// Preview Page
const AsyncFundingNeedDetailedPage = Loadable({
  loader: () => import('./fundingNeeds/Preview'),
  loading: LoadingComponent,
})

// User Dashboard
const AsyncUserRolesPage = Loadable({
  loader: () => import('./features/user/UserDashboard'),
  loading: LoadingComponent,
})

const AsyncModalManager = Loadable({
  loader: () => import('./features/modals/ModalManager'),
  loading: LoadingComponent,
})

const AsyncNotFound = Loadable({
  loader: () => import('./pages/NotFound'),
  loading: LoadingComponent,
})

const App = ({ user }) => {
  if (!user) {
    return <div />
  }

  // Keeping this here to test auth against client
  // return (
  //   <Switch>
  //     <Route exact path="/" component={() => { return <p>Un Auth'd Landing</p> }} />
  //     <Route exact path="/oauth/callback" component={CallbackPage} />
  //     <CheckAuth>
  //       <Route exact path="/authd" component={() => { return <p>I should be auth'd</p> }} />
  //     </CheckAuth>
  //   </Switch>
  // )

  return (
    <>
      <AsyncModalManager />
      <Switch>
        <Route exact path="/" component={AsyncHomePage} />
        <Route exact path="/oauth/callback" component={CallbackPage} />
        <Route exact path="/oauth/renew" component={RenewPage} />
        <Route exact path="/about" component={AsyncAboutPage} />
        {/* <Route component={AsyncNotFound} /> */}
      </Switch>

      <Route
        path="/(.+)"
        render={() => (
          <>
            <Switch>
              <CheckAuth>
                <AsyncNavBar />
                <FundingNeedProvider>
                  <Route exact path="/dashboard" component={AsyncDashboard} />
                  <Route exact path="/dashboard/archived" component={AsyncArchiveDashboard} />
                  <Route exact path="/dashboard/my-funding-needs" component={AsyncMyNeedsDashboard} />
                  <UserProvider>
                    <Route exact path="/users" component={AsyncUserRolesPage} />
                  </UserProvider>
                  <Route exact path="/add" component={AsyncFundingNeedCreateEditPage} />
                  <Route exact path="/preview/:sfid" component={AsyncFundingNeedDetailedPage} />
                  <Route exact path="/preview/:sfid/:fileId" component={AsyncFundingNeedDetailedPage} />
                  <Route path="/edit/:sfid" component={AsyncFundingNeedCreateEditPage} />
                </FundingNeedProvider>
              </CheckAuth>
              <Route component={AsyncNotFound} />
              <a>Feedback</a>
            </Switch>
          </>
        )}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  const user = state.oidc.user ? state.oidc.user.profile : {}
  return {
    user,
  }
}

export default connect(mapStateToProps)(App)
