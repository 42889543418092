import React, { useState, useEffect } from 'react'

const UserContext = React.createContext([{}, () => { }])

const UserProvider = ({ children }) => {
  const [state, setState] = useState({
    snapManagers: [],
    snapAdmins: [],
  })

  const [loading, setLoading] = useState(false)

  return (
    <UserContext.Provider value={[state, setState, loading, setLoading]}>
      {children}
    </UserContext.Provider>
  )
}

export { UserContext, UserProvider }
