import { FETCH_ROLES, FETCH_MANAGERS, FETCH_ADMINS } from './types'
import { createReducer } from '../../../common/utils/reducerUtil'

const initialState = {
  roles: undefined,
  managers: undefined,
  admins: undefined,
}

export const fetchRoles = (state, payload) => {
  return {
    ...state,
    roles: payload.roles,
  }
}

export const fetchManagers = (state, payload) => {
  return {
    ...state,
    managers: payload.managers,
  }
}

export const fetchAdmins = (state, payload) => {
  return {
    ...state,
    admins: payload.admins,
  }
}

export default createReducer(initialState, {
  [FETCH_ROLES]: fetchRoles,
  [FETCH_MANAGERS]: fetchManagers,
  [FETCH_ADMINS]: fetchAdmins,
})
