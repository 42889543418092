import { useState, useEffect } from 'react'
import userManager from '../../../utils/userManager'
import { initialize } from 'redux-form'

function useAuth(location) {
  const [authdUser, setAuthdUser] = useState(null);

  /** 
   * Everytime location is changed, and we're under a protected route
   * this useEffect should be run to validate the user.
   */
  useEffect(() => {
    async function init() {
      localStorage.setItem('uri', location.search) // Setting uri temp in local storage to retrieve it later after all the redirects
      // we want to check that we're not doing any auth actions on the callback page
      // @TODO We may not need this if check here, check to see if this path is hit, if not remove it
      if (location && location.pathname !== '/oauth/callback') {
        try {
          // call the user manager and get the User
          // This will go into session storage and get the user if it exists
          const user = await userManager.getUser()
          // validate we got a user, and they have not expired
          if (!user || user.expired) {
            // the user has expired, need to renew
            setAuthdUser(null);
            const uri = localStorage.getItem('uri')
            localStorage.removeItem('uri')
            //@TODO Better document this function
            userManager.signinRedirect({
              data: {
                path: window.location.pathname + uri,
              }
            });
          }
          // user exists, so lets set them as the authenticated user
          setAuthdUser(user)
        } catch (err) {
          console.error('Error getting the user', err)
        }
      }
    }
    init();
  }, [location])

  return [authdUser]
}

export default useAuth
