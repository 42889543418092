import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import ReduxToastr from 'react-redux-toastr'
import { OidcProvider, loadUser } from 'redux-oidc'
import { QueryParamProvider } from 'use-query-params'
import ScrollToTop from './app/common/utils/ScrollToTop'
import 'semantic-ui-css/semantic.min.css'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import './styles/main.css'
import App from './app/App'
import { configureStore } from './app/configureStore'
import * as serviceWorker from './serviceWorker'
import userManager from './utils/userManager'

if (process.env.NODE_ENV !== 'production') {
  require('dotenv').config()
}

const store = configureStore()
// Loads potentially existing user data into the redux store,
// thus eliminating a new authentication roundtrip to the authentication server
// when a tab is closed or a new tab is opened.
// see here: https://github.com/maxmantz/redux-oidc/blob/master/docs/API.md#loaduser
loadUser(store, userManager)

// To see verbose logging around Oidc Client
// See here for more information: https://github.com/IdentityModel/oidc-client-js/wiki#logging

if (process.env.NODE_ENV !== 'production') {
  // Oidc.Log.logger = console;
}

const rootEl = document.getElementById('root')
const render = () => {
  ReactDOM.render(
    // <AnalyticsProvider instance={analytics}>
    <Provider store={store}>
      <OidcProvider userManager={userManager} store={store}>
        <Router>
          <QueryParamProvider ReactRouterRoute={Route}>
            <ScrollToTop>
              <ReduxToastr
                preventDuplicates
                position="bottom-right"
                transitionIn="fadeIn"
                transitionOut="fadeOut"
              />
              <App />
            </ScrollToTop>
          </QueryParamProvider>
        </Router>

      </OidcProvider>
    </Provider>,
    // </AnalyticsProvider>,
    rootEl,
  )
}

if (module.hot) {
  module.hot.accept('./app/App', () => {
    setTimeout(render)
  })
}

render()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
