import React from 'react'
import { CallbackComponent } from 'redux-oidc'
import { withRouter } from 'react-router-dom'

import userManager from '../../../utils/userManager'

const CallbackPage = ({ history }) => {
  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={({ id_token, state = {} }) => {
        const path = state.path ? state.path : '/dashboard'
        history.push(path)
      }}
      errorCallback={error => {
        console.error("Error on auth callback", error)
        history.push('/')
      }}>
      <div>Redirecting...</div>
    </CallbackComponent>
  )
}

export default withRouter(CallbackPage)