import React from 'react'
import { withRouter, useLocation } from 'react-router-dom'
import useAuth from './useAuth'

const CheckAuth = ({ children }) => {
  const location = useLocation()
  const [authdUser] = useAuth(location)

  // Check if the user is auth'd if they are show the page, otherwise load a blank page
  // until they are auth'd
  // @TODO Add a loader on the Auth'd user if they do not exist.
  return <>{authdUser ? <>{children}</> : ''}</>
}

export default withRouter(CheckAuth)
