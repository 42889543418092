import React, { useState, useEffect } from 'react'

const FundingNeedContext = React.createContext([{}, () => { }])

const FundingNeedProvider = ({ children }) => {
  const [state, setState] = useState({
    fundingNeedManagers: [],
  })

  const [loading, setLoading] = useState(false)

  return (
    <FundingNeedContext.Provider value={[state, setState, loading, setLoading]}>
      {children}
    </FundingNeedContext.Provider>
  )
}

export { FundingNeedContext, FundingNeedProvider }
